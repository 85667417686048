<script>
export default {
  props: {
    type: String,
    navigations: Array,
  },
  setup() {
    const auth = useAuthStore();
    const can = auth.can;
    const module = useModuleStore();
    return { can, module };
  },
  methods: {
    isShow(payload) {
      let show = true;
      if (payload.module) {
        payload.module.forEach((module) => {
          if (show && this.module.checkModule(module) == false) show = false;
        });
      }
      return show;
    },
    hasPermission(permission) {
      let granted = false;
      if (permission instanceof Array) {
        permission.forEach((item) => {
          if (this.can(`${item}-index`) || this.can(`${item}-view`))
            granted = true;
        });
      } else {
        granted = this.can(`${permission}-index`);
      }
      return granted;
    },
  },
  // can(`${tab.permission}-index`)
};
</script>

<template>
  <template v-for="(tab, index) in navigations" :key="index">
    <li
      v-if="isShow(tab) && (hasPermission(tab.permission) || !tab.permission)"
      class="uk-nav-header uk-margin-remove-top navigation-title"
    >
      <span class="navigation-side-label"
        ><b>{{ $t(tab.title) }}</b></span
      >
    </li>
    <template v-for="tab in tab.children" :key="tab.link">
      <li
        v-if="
          isShow(tab) && hasPermission(tab.permission) && tab.children == null
        "
        class="link"
        :class="{ active: $route.path == tab.link }"
      >
        <RouterLink :to="tab.link">
          <span
            class="uk-padding-small uk-padding-remove-horizontal uk-flex uk-flex-middle"
          >
            <component v-bind:is="tab.icon" />
          </span>
          <span class="navigation-side-label">{{ $t(tab.title) }}</span>
        </RouterLink>
      </li>
      <li
        v-else-if="
          isShow(tab) && hasPermission(tab.permission) && tab.children != null
        "
        class="nested-item"
        :class="{ active: $route.path == tab.link }"
      >
        <ul uk-accordion>
          <li>
            <a class="uk-accordion-title" href>
              <div class="uk-flex uk-flex-middle">
                <span class="uk-padding-small uk-padding-remove-horizontal">
                  <component v-bind:is="tab.icon" />
                </span>
                <span class="navigation-side-label" style="margin-left: 4px">{{
                  $t(tab.title)
                }}</span>
              </div>
            </a>
            <div
              class="uk-accordion-content"
              style="margin-top: 0px !important"
            >
              <template v-for="nested in tab.children">
                <li
                  v-if="isShow(nested) && hasPermission(nested.permission)"
                  class="children"
                  :class="{ active: $route.path == nested.link }"
                >
                  <RouterLink :to="nested.link">
                    <span
                      class="uk-padding-small uk-padding-remove-horizontal uk-flex uk-flex-middle"
                    >
                      <component v-bind:is="nested.icon" />
                    </span>
                    <span class="navigation-side-label">{{
                      $t(nested.title)
                    }}</span>
                  </RouterLink>
                </li>
              </template>
            </div>
          </li>
        </ul>
      </li>
    </template>
  </template>
</template>
<style scoped>
.navigation-title {
  position: relative;
  padding-top: 10px;
}

.navigation-title::before {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  top: 0;
  left: 5px;
  right: 5px;
}

.uk-accordion-title {
  margin: 10px 10px 10px 10px;
}

li.active a {
  color: #ffffff;
}

.children {
  padding: 5px 5px 5px 20px;
}

.router-link-active .router-link-exact-active {
  padding: 10px 10px 10px 10px;
}

li.active {
  padding: 5px 5px 5px 20px;
  background-color: #0a1045;
  border-radius: 0px 15px 15px 0px !important;
}
</style>
